.ui.segment.app-wrapper {
  max-width: 500px;
  margin: auto!important;
  padding-top: 0;
  padding-bottom: 0;

  .header-wrapper {
    margin-bottom: 0;
    .header {
      margin-bottom: 0;
    }
  }

  .hint {
    margin-top: 0;
  }

  .game-grid-wrapper {
    margin-top: 0;
  }
}

.message.negative {
  position: absolute;
  left: calc(50% - 150px);
  top: 20px;
  width: 300px;
  z-index:  1;
  text-align:  center;
}

.float-right {
  float: right;
}